import React, { FC } from "react";
import "./index.scss";
import { strings } from "../../helpers/dashboardStrings";

const Rules: FC = () => {
  return (
    <div className="rules">
      <div className="rules-title">{strings.generalRulesTitle}</div>
      <div className="rules-body">{strings.generalRulesDesc}</div>
    </div>
  );
};

export default Rules;
