// const REACT_APP_API_URL = process.env.API_URL;
const REACT_APP_API_URL = "https://yasdestek.com/api";
const LOGIN_ENDPOINT = "/users/login";
const USERS_ENDPOINT = "/users";
const MODULE_LIST = "/modules";
const MODULE_INFO = "/modules/{id}";
const GET_MODULE_ENTRIES = "/module_entries/?module_id={moduleId}";
const GET_IMAGE_BY_IMAGE_ID = "/module_entries/image?image_id={imageId}";
const GET_VOICE_BY_VOICE_ID = "/module_entries/voice?voice_id={voiceId}";
const GET_MODULE_ENTRY_BY_USER =
  "/module_entries/user?module_id={moduleId}&user_id={userId}";
const CREATE_MODULE_ENTRIES = "/module_entries/";
const CREATE_COMMENT_ENTRY = "/comment_entries/";
const SEND_MAIL = "/users/mail";
const USER_INFO = "/users/me";
const LIST_USERS = "/users/";
export {
  REACT_APP_API_URL,
  LOGIN_ENDPOINT,
  USERS_ENDPOINT,
  MODULE_LIST,
  MODULE_INFO,
  GET_MODULE_ENTRIES,
  GET_IMAGE_BY_IMAGE_ID,
  GET_VOICE_BY_VOICE_ID,
  CREATE_MODULE_ENTRIES,
  CREATE_COMMENT_ENTRY,
  USER_INFO,
  LIST_USERS,
  GET_MODULE_ENTRY_BY_USER,
  SEND_MAIL,
};
