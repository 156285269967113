import React, { FC } from "react";
import "./index.scss";
import { strings } from "../../helpers/dashboardStrings";

const Welcome: FC = () => {
  return (
    <div className="welcome">
      <div className="welcome-body">{strings.welcomeText}</div>
    </div>
  );
};

export default Welcome;
