import {
  REACT_APP_API_URL,
  LOGIN_ENDPOINT,
  MODULE_LIST,
  MODULE_INFO,
  GET_MODULE_ENTRIES,
  GET_IMAGE_BY_IMAGE_ID,
  GET_VOICE_BY_VOICE_ID,
  CREATE_MODULE_ENTRIES,
  CREATE_COMMENT_ENTRY,
  USER_INFO,
  LIST_USERS,
  GET_MODULE_ENTRY_BY_USER,
  SEND_MAIL,
} from "../config/config";

const handleLogin = async (username, password) => {
  const response = await fetch(`${REACT_APP_API_URL}${LOGIN_ENDPOINT}`, {
    method: "POST",
    body: JSON.stringify({ email: username, password }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (response.ok) {
    const responseData = await response.json();
    localStorage.setItem("isLoggedIn", true);
    localStorage.setItem("username", username);
    localStorage.setItem("accessToken", responseData.access_token);
  } else {
    const errorData = await response.json();
    throw new Error(errorData);
  }
};

const handleSetModuleAnswer = async (moduleAnswer) => {
  try {
    const response = await fetch(
      `${REACT_APP_API_URL}${CREATE_MODULE_ENTRIES}`,
      {
        method: moduleAnswer.id ? "PUT" : "POST",
        body: JSON.stringify({
          id: moduleAnswer?.id,
          module_id: moduleAnswer?.module_id,
          user_id: moduleAnswer?.user_id,
          text: moduleAnswer?.text,
          voice: moduleAnswer?.voice,
          images: moduleAnswer?.images,
          username: moduleAnswer?.username,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        mode: "cors",
      }
    );

    if (response.ok) {
    } else {
      const errorData = await response.json();
      localStorage.setItem("moduleEntry", true);
      console.log("module entry upload başarısız:", errorData.message);
    }
  } catch (error) {}
};

const handleDeleteModuleAnswer = async (moduleAnswer) => {
  try {
    const response = await fetch(
      `${REACT_APP_API_URL}${CREATE_MODULE_ENTRIES}`,
      {
        method: "DELETE",
        body: JSON.stringify({
          id: moduleAnswer?.id,
          module_id: moduleAnswer?.module_id,
          user_id: moduleAnswer?.user_id,
          text: moduleAnswer?.text,
          voice: moduleAnswer?.voice,
          images: moduleAnswer?.images,
          username: moduleAnswer?.username,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        mode: "cors",
      }
    );

    if (response.ok) {
    } else {
      const errorData = await response.json();
      localStorage.setItem("moduleEntry", true);
      console.log("module entry delete başarısız:", errorData.message);
    }
  } catch (error) {}
};

const handleSetCommentEntry = async (forumAnswer) => {
  try {
    const response = await fetch(
      `${REACT_APP_API_URL}${CREATE_COMMENT_ENTRY}`,
      {
        method: "POST",
        body: JSON.stringify({
          module_id: forumAnswer?.module_id,
          module_entry_id: forumAnswer?.module_entry_id,
          user_id: forumAnswer?.user_id,
          text: forumAnswer?.text,
          parent_id: forumAnswer?.parent_id,
          username: forumAnswer?.username,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        mode: "cors",
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      console.log("forum entry responseData:", responseData);
    } else {
      const errorData = await response.json();
      localStorage.setItem("forumEntry", true);
      console.log("forum entry upload başarısız:", errorData.message);
    }
  } catch (error) {
    console.log("Hata:", error.message);
  }
};

const handleSendMail = async (mailRequest) => {
  const response = await fetch(`${REACT_APP_API_URL}${SEND_MAIL}`, {
    method: "POST",
    body: JSON.stringify({
      text: mailRequest?.text,
      module_id: mailRequest?.module_id,
      user_id: parseInt(mailRequest?.user_id),
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  });

  if (response.ok) {
    const responseData = await response.json();
    console.log("Mail Gönderildi", responseData);
  } else {
    const errorData = await response.json();
    throw new Error(errorData);
  }
};

const handleDeleteCommentEntry = async (forumAnswer) => {
  try {
    const response = await fetch(
      `${REACT_APP_API_URL}${CREATE_COMMENT_ENTRY}`,
      {
        method: "DELETE",
        body: JSON.stringify({
          module_id: forumAnswer?.module_id,
          module_entry_id: forumAnswer?.module_entry_id,
          user_id: forumAnswer?.user_id,
          text: forumAnswer?.text,
          parent_id: forumAnswer?.parent_id,
          username: forumAnswer?.username,
          id: forumAnswer?.id,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        mode: "cors",
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      console.log("forum entry responseData:", responseData);
    } else {
      const errorData = await response.json();
      localStorage.setItem("forumEntry", true);
      console.log("forum entry upload başarısız:", errorData.message);
    }
  } catch (error) {
    console.log("Hata:", error.message);
  }
};

const getModuleList = async () => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}${MODULE_LIST}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    if (response.ok) {
      const responseData = await response.json();
      return responseData.modules;
    } else {
      const errorData = await response.json();
      console.log("Moduller çekilirken bir hata oluştu:", errorData.message);
    }
  } catch (error) {
    console.log("Hata:", error.message);
  }
};

const getModuleInfo = async (id) => {
  try {
    const response = await fetch(
      `${REACT_APP_API_URL}${MODULE_INFO.replace("{id}", id)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    } else {
      const errorData = await response.json();
      console.log("Modul info çekilirken bir hata oluştu:", errorData.message);
    }
  } catch (error) {
    console.log("Hata:", error.message);
  }
};

const getModuleEntries = async (id) => {
  try {
    const response = await fetch(
      `${REACT_APP_API_URL}${GET_MODULE_ENTRIES.replace("{moduleId}", id)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    }
  } catch (error) {
    console.log("Hata:", error.message);
  }
};

const getImage = async (id) => {
  try {
    const response = await fetch(
      `${REACT_APP_API_URL}${GET_IMAGE_BY_IMAGE_ID.replace("{imageId}", id)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    }
  } catch (error) {
    console.log("Hata:", error.message);
  }
};

const getVoice = async (id) => {
  try {
    const response = await fetch(
      `${REACT_APP_API_URL}${GET_VOICE_BY_VOICE_ID.replace("{voiceId}", id)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    }
  } catch (error) {
    console.log("Hata:", error.message);
  }
};

const getModuleEntryByUserId = async (module_id, user_id) => {
  try {
    const response = await fetch(
      `${REACT_APP_API_URL}${GET_MODULE_ENTRY_BY_USER.replace(
        "{moduleId}",
        module_id
      ).replace("{userId}", user_id)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    } else {
      const errorData = await response.json();
      console.log(
        "Modul entries çekilirken bir hata oluştu:",
        errorData.message
      );
    }
  } catch (error) {
    console.log("Hata:", error.message);
  }
};

const handleUserInfo = async () => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}${USER_INFO}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    if (response.ok) {
      const responseData = await response.json();
      localStorage.setItem("userId", responseData.id);
      localStorage.setItem("userFullName", responseData.full_name);
      localStorage.setItem("userRole", responseData.role);

      window.location.href = "/";
    } else {
      const errorData = await response.json();
      localStorage.setItem("moduleEntry", true);
      console.log("module entry upload başarısız:", errorData);
    }
  } catch (error) {
    console.log("Hata:", error.message);
  }
};

const getUserList = async () => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}${LIST_USERS}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    if (response.ok) {
      const responseData = await response.json();
      return responseData.users;
    } else {
      const errorData = await response.json();
      console.log(":::ADMIN user list get başarısız", errorData.message);
    }
  } catch (error) {
    console.log(":::ADMIN user list get başarısız t", error.message);
  }
};

const createNewUser = async (user) => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}${LIST_USERS}`, {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    const responsej = await response.json();
    if (response.status >= 400 && response.status < 600) {
      throw new Error("Kullanıcı oluşturulamadı");
    }
    return responsej;
  } catch (error) {
    console.log(":::ADMIN user create başarısız t", error.message);
    throw error;
  }
};

const updateUserInfo = async (user) => {
  try {
    await fetch(`${REACT_APP_API_URL}${LIST_USERS}`, {
      method: "PUT",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
  } catch (error) {
    console.log(":::ADMIN user list update başarısız", error.message);
    throw error;
  }
};

const deleteUser = async (user) => {
  try {
    const response = await fetch(
      `${REACT_APP_API_URL}${LIST_USERS}/${user.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      return responseData.users;
    } else {
      const errorData = await response.json();
      console.log(":::ADMIN user list get başarısız", errorData.message);
    }
  } catch (error) {
    console.log(":::ADMIN user list get başarısız t", error.message);
  }
};

const updateModuleInfo = async (user) => {
  try {
    await fetch(`${REACT_APP_API_URL}${MODULE_LIST}`, {
      method: "PUT",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
  } catch (error) {
    console.log(":::ADMIN module list update başarısız t", error.message);
    throw error;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  handleLogin,
  getModuleList,
  getModuleInfo,
  getModuleEntries,
  getImage,
  getVoice,
  handleSetModuleAnswer,
  handleUserInfo,
  handleSetCommentEntry,
  handleDeleteCommentEntry,
  handleDeleteModuleAnswer,
  getUserList,
  createNewUser,
  updateUserInfo,
  updateModuleInfo,
  getModuleEntryByUserId,
  handleSendMail,
  deleteUser,
};
