import { FC } from "react";
import "./index.scss";
import { BsXLg } from "react-icons/bs";

export interface ImagePreviewProps {
  photo: string;
  setSelectedPhoto: React.Dispatch<React.SetStateAction<string>>;
}

const ImagePreview: FC<ImagePreviewProps> = ({ photo, setSelectedPhoto }) => {
  const handleClose = () => {
    setSelectedPhoto("");
  };
  return (
    <div className="image-preview-window">
      <div className="image-preview-container">
        <div className="image-preview-header">
          <BsXLg className="image-preview-close" onClick={handleClose}></BsXLg>
        </div>
        <img
          alt="Fotoğraf önizlemesi"
          className="image-preview-image"
          src={photo}
        ></img>
      </div>
    </div>
  );
};

export default ImagePreview;
