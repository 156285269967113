import React, { useCallback, useEffect, useState } from "react";
import "./index.scss";
import Info from "../../components/Info";
import api from "../../utils/api";
import { ModuleItemType } from "../../helpers/dashboardModels";
import { Outlet, useNavigate } from "react-router-dom";

const Dashboard: React.FC = () => {
  const [generalRulesOpened, setGeneralRulesOpened] = useState<boolean>(true);
  const [modules, setModules] = useState<ModuleItemType[]>();
  const [selectedItemId, setSelectedItemId] = useState<string>("");

  const navigate = useNavigate();

  const handleNavigate = useCallback(
    () => navigate("/login", { replace: true }),
    [navigate]
  );

  useEffect(() => {
    if (
      selectedItemId &&
      selectedItemId.substring(selectedItemId.lastIndexOf("-") + 1) !== "0"
    ) {
      setGeneralRulesOpened(false);
    } else setGeneralRulesOpened(true);
  }, [selectedItemId]);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) handleNavigate();
  }, [handleNavigate]);

  useEffect(() => {
    const fetchModules = async () => {
      try {
        const response = await api.getModuleList();
        setModules(response);
      } catch (error) {}
    };

    fetchModules();
  }, []);

  useEffect(() => {
    if (generalRulesOpened === true) setSelectedItemId("");
  }, [generalRulesOpened]);

  return (
    <div className="dashboard">
      <Info
        setGeneralRulesOpened={setGeneralRulesOpened}
        setSelectedItemId={setSelectedItemId}
        modules={modules}
      />
      <div className="dashboard__seperator"></div>
      <div className="dashboard__item">
        <Outlet></Outlet>
      </div>
    </div>
  );
};

export default Dashboard;
