import React, { useState } from "react";
import {
  BsExclamationCircleFill,
  BsEye,
  BsEyeSlash,
  BsXLg,
} from "react-icons/bs";
import "./index.scss";
import Modal from "react-modal";
import api from "../../utils/api";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    display: "flex",
    "flex-direction": "column",
    "align-items": "center",
  },
};

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPwd, setShowPwd] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<boolean>(false);

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const showHidePwd = () => {
    setShowPwd(!showPwd);
  };

  const onLoginClick = async () => {
    api
      .handleLogin(username, password)
      .then(() => api.handleUserInfo())
      .catch((err) => {
        setLoginError(err);
      });
  };

  const handleClose = () => {
    setLoginError(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onLoginClick();
    }
  };

  return (
    <div className="login-page">
      {!loginError && (
        <>
          <div className="login-page__container">
            <h2 className="login-page__title">Hoş Geldiniz</h2>
            <div className="login-page__form">
              <div className="login-page__form-container">
                <div className="login-page__pwd-input-container">
                  <input
                    className="login-page__input"
                    type="text"
                    id="username"
                    value={username}
                    onChange={handleUsernameChange}
                    placeholder="Kullanıcı Adı"
                  />
                </div>
                <div className="login-page__pwd-input-container">
                  <input
                    className="login-page__input"
                    type={showPwd ? "text" : "password"}
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder="Parola"
                    onKeyDown={handleKeyDown}
                  ></input>
                  {showPwd && (
                    <BsEye className="login-page__icon" onClick={showHidePwd} />
                  )}
                  {!showPwd && (
                    <BsEyeSlash
                      className="login-page__icon"
                      onClick={showHidePwd}
                    />
                  )}
                </div>
                <button
                  onClick={onLoginClick}
                  className="login-page__login-button"
                >
                  Giriş
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {loginError && (
        <Modal
          isOpen={loginError}
          onRequestClose={handleClose}
          style={customStyles}
        >
          <div className="login-page__module-header">
            <BsXLg
              onClick={handleClose}
              size="22px"
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="login-page__module-body">
            <h2 className="login-page__module-title">
              <p style={{ justifyContent: "center", alignItems: "center" }}>
                <BsExclamationCircleFill color="#345a57" />
                &nbsp;&nbsp;Kullanıcı Adı - Şifre Hatalı
              </p>
              <p>Lütfen Araştırmacınız İle İletişime Geçiniz</p>
            </h2>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default LoginPage;
