import React, { FC } from "react";
import "./index.scss";
import Modules from "../Modules";
import { ModuleItemType } from "../../helpers/dashboardModels";
import { Link, useNavigate } from "react-router-dom";
import { BsDoorOpenFill } from "react-icons/bs";

export interface InfoProps {
  setGeneralRulesOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedItemId: React.Dispatch<React.SetStateAction<string>>;
  modules: ModuleItemType[] | undefined;
}

const Info: FC<InfoProps> = ({
  setGeneralRulesOpened,
  setSelectedItemId,
  modules,
}) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    navigate("/login");
  };
  const name = localStorage.getItem("userFullName");
  const role = localStorage.getItem("userRole") || "0";
  return (
    <div className="info">
      <h1 className="info__title">{name || "Kullanıcı"}</h1>

      <div className="info__title-seperator"></div>
      <div className="info-header-buttons">
        <Link to="/rules" className="info__rule-button info-header-rules">
          Genel Kurallar
        </Link>
        {parseInt(role) === 2 && (
          <Link to="/admin" className="info__rule-button info-header-admin">
            Yönetim
          </Link>
        )}
      </div>

      <Modules setSelectedItemId={setSelectedItemId} modules={modules} />
      <div className="info-logout" onClick={handleLogout}>
        <BsDoorOpenFill></BsDoorOpenFill>&nbsp; Çıkış Yap
      </div>
    </div>
  );
};

export default Info;
