import { FC, useEffect, useState } from "react";
import "./index.scss";
import { BsFillChatLeftDotsFill, BsTrash, BsTrash3Fill } from "react-icons/bs";
import ForumEntry from "../ForumEntry";
import { ModuleEntryInfo } from "../../helpers/dashboardModels";
import ImagePreview from "../ImagePreview";
import { isEmpty } from "lodash";
import api from "../../utils/api";
import DeleteModal from "../CommentDeleteModal";

export interface ModuleEntryProps {
  moduleEntryData: ModuleEntryInfo;
  moduleId: string;
}

const ModuleEntry: FC<ModuleEntryProps> = ({ moduleEntryData, moduleId }) => {
  const [audioBlob, setAudioBlob] = useState<string>();
  const [selectedPhoto, setSelectedPhoto] = useState<string>("");
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [images, setImages] = useState<Array<string>>([]);
  const [voice, setVoice] = useState<string>("");
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const [loadingVoice, setLoadingVoice] = useState<boolean>(false);
  const [moduleEntry, setModuleEntry] = useState<object>({});
  const uploadedImages = ["", "", ""];

  const role = localStorage.getItem("userRole") || "0";
  const [forumEntryCommentClicked, setForumEntryCommentClicked] =
    useState<boolean>(false);

  const handleForumComment = () => {
    setForumEntryCommentClicked(true);
  };

  const handleDeleteComment = () => {
    setDeleteModalOpen(true);
    const forumAnswer = {
      id: moduleEntryData?.id,
      module_id: moduleEntryData?.module_id,
      user_id: localStorage.getItem("userId"),
      text: moduleEntryData.text,
      voice: null,
      images: [],
      username: moduleEntryData?.username,
    };
    setModuleEntry(forumAnswer);
  };

  const handleDeleteModal = () => {
    api.handleDeleteModuleAnswer(moduleEntry).then(() => {
      window.location.reload();
    });
  };

  const getVoiceRecord = (voice: string) => {
    setLoadingVoice(true);
    api
      .getVoice(voice)
      .then((resp) => {
        setVoice(resp);
      })
      .catch(() => {
        console.log("ses kaydı çekilirken hata oluştu");
      })
      .finally(() => {
        setLoadingVoice(false);
      });
  };

  useEffect(() => {
    (async () => {
      setAudioBlob(URL.createObjectURL(await (await fetch(voice)).blob()));
    })();
  }, [voice]);

  useEffect(() => {
    getVoiceRecord(moduleEntryData.voice);
    setLoadingImage(true);
    Promise.all(moduleEntryData?.images?.map((img) => api.getImage(img)))
      .then((imageArray) => {
        setImages(imageArray);
      })
      .finally(() => {
        setLoadingImage(false);
      });
  }, [moduleEntryData]);

  return (
    <div className="module-entry">
      {selectedPhoto && (
        <ImagePreview
          photo={selectedPhoto}
          setSelectedPhoto={setSelectedPhoto}
        ></ImagePreview>
      )}
      {deleteModalOpen && (
        <DeleteModal
          handleDeleteModal={handleDeleteModal}
          setDeleteModalOpen={setDeleteModalOpen}
        ></DeleteModal>
      )}
      <div className="module-entry-container">
        <div className="module-entry-container-body">
          <div className="module-entry-title">
            <div>{moduleEntryData.username}</div>
            {parseInt(role) === 2 && (
              <BsTrash3Fill
                className="module-entry-trash"
                onClick={handleDeleteComment}
              ></BsTrash3Fill>
            )}
          </div>
          <div className="module-entry-body" style={{ whiteSpace: "pre-line" }}>
            {moduleEntryData.text}
          </div>
        </div>
        {moduleEntryData.images && (
          <>
            {loadingImage && (
              <div
                className="module-entry-photos"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Yükleniyor...
              </div>
            )}
            {!loadingImage && (
              <div className="module-entry-photos">
                {!isEmpty(images[0]) && (
                  <div className="module-entry-photos-thumb">
                    <img
                      className="module-entry-photos-photo"
                      alt="Fotoğraf 1"
                      src={images[0]}
                      onClick={() => setSelectedPhoto(images[0])}
                    ></img>
                  </div>
                )}
                {!isEmpty(images[1]) && (
                  <div className="module-entry-photos-thumb">
                    <img
                      className="module-entry-photos-photo"
                      alt="Fotoğraf 2"
                      src={images[1]}
                      onClick={() => setSelectedPhoto(images[1])}
                    ></img>
                  </div>
                )}
                {!isEmpty(images[2]) && (
                  <div className="module-entry-photos-thumb">
                    <img
                      className="module-entry-photos-photo"
                      alt="Fotoğraf 3"
                      src={images[2]}
                      onClick={() => setSelectedPhoto(images[2])}
                    ></img>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <div className="module-entry-footer">
        {loadingVoice && (
          <div className="module-entry-footer-voice">Yükleniyor...</div>
        )}
        {!loadingVoice && (
          <div className="module-entry-footer-voice">
            <audio src={audioBlob} controls></audio>
          </div>
        )}
        <div
          className="module-entry-footer-comment"
          style={{ cursor: "pointer" }}
          onClick={handleForumComment}
        >
          <BsFillChatLeftDotsFill />
          &nbsp; Yorum Yap
        </div>
      </div>
      {moduleEntryData.comments && (
        <div className="module-entry-children">
          {moduleEntryData.comments.map((element) => {
            return (
              <ForumEntry
                commentEntry={element}
                moduleEntryId={moduleEntryData.id.toString()}
                level={0}
              ></ForumEntry>
            );
          })}
          {forumEntryCommentClicked && (
            <ForumEntry
              moduleEntryId={moduleEntryData.id.toString()}
              moduleId={moduleId}
              level={0}
            ></ForumEntry>
          )}
        </div>
      )}
    </div>
  );
};

export default ModuleEntry;
