import { FC, useState } from "react";
import "./index.scss";
import { BsXLg } from "react-icons/bs";
import { ModuleInfo } from "../../helpers/dashboardModels";

export interface ModuleEditModalProps {
  moduleInfo: ModuleInfo;
  setSelectedModule: React.Dispatch<
    React.SetStateAction<ModuleInfo | undefined>
  >;
  updateSelectedModule: (module: ModuleInfo) => void;
}

const ModuleEditModal: FC<ModuleEditModalProps> = ({
  moduleInfo,
  setSelectedModule,
  updateSelectedModule,
}) => {
  const [moduleModel, setModuleModel] = useState<ModuleInfo>(moduleInfo);
  const handleClose = () => {
    setSelectedModule(undefined);
  };
  const handleUserSubmit = () => {
    updateSelectedModule(moduleModel);
  };
  return (
    <div className="modal-edit-window">
      <div className="modal-edit-container">
        <div className="modal-edit-header">
          <div className="modal-edit-header-title">
            Düzenlenen modül: {moduleModel.name}
          </div>
          <BsXLg className="modal-edit-close" onClick={handleClose}></BsXLg>
        </div>
        <div className="modal-edit-body">
          <div className="modal-edit-body-item">
            <div className="modal-edit-body-item-title">Modül İsmi</div>
            <input
              defaultValue={moduleModel.name}
              onChange={(e) =>
                setModuleModel({
                  ...moduleModel,
                  name: e.currentTarget.value,
                })
              }
            ></input>
          </div>
          <div className="modal-edit-body-item">
            <div className="modal-edit-body-item-title">Modül Açıklaması</div>
            <textarea
              defaultValue={moduleModel.text}
              onChange={(e) =>
                setModuleModel({
                  ...moduleModel,
                  text: e.currentTarget.value,
                })
              }
            ></textarea>
          </div>
          <div className="modal-edit-body-item">
            <div className="modal-edit-body-item-title">Modül Kuralları</div>
            <textarea
              defaultValue={moduleModel.rules}
              onChange={(e) =>
                setModuleModel({
                  ...moduleModel,
                  rules: e.currentTarget.value,
                })
              }
            ></textarea>
          </div>
          <div className="modal-edit-body-item">
            <div className="modal-edit-body-item-title">Resimli Modül</div>
            <select
              defaultValue={moduleModel.img ? 1 : 0}
              onChange={(e) =>
                setModuleModel({
                  ...moduleModel,
                  img: !!parseInt(e.currentTarget.value),
                })
              }
            >
              <option value={0}>Hayır</option>
              <option value={1}>Evet</option>
            </select>
          </div>
        </div>
        <div className="modal-edit-submit">
          <button
            className="modal-edit-submit-button"
            onClick={handleUserSubmit}
          >
            Kaydet
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModuleEditModal;
