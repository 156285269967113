import React, { FC, useEffect, useState } from "react";
import "./index.scss";
import { ModuleEntryInfo, ModuleInfo } from "../../helpers/dashboardModels";
import api from "../../utils/api";
import { isEmpty } from "lodash";
import { BsArrowLeftSquare, BsTrashFill } from "react-icons/bs";
import VoiceRecorder from "../VoiceRecorder";
import Loader from "../Loader";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

const ModuleLetter: FC = () => {
  const [loaderEnabled, setLoaderEnabled] = useState<boolean>(false);
  const [moduleInfo, setModuleInfo] = useState<ModuleInfo>();
  const [moduleInput, setModuleInput] = useState<string>("");
  const [moduleAudio, setModuleAudio] = useState<Blob>();
  const [modulePhotos, setModulePhotos] = useState<Array<string>>(["", "", ""]);
  const [moduleEntryId, setModuleEntryId] = useState<number | null>(null);
  const [moduleEntry, setModuleEntry] = useState<ModuleEntryInfo>();
  const [voice, setVoice] = useState<string>("");
  const [loadingVoice, setLoadingVoice] = useState<boolean>(false);
  const uploadedImages = ["", "", ""];

  const { moduleId } = useParams() as { moduleId: string };

  const postModuleAnswer = () => {
    const fReader = new FileReader();

    const moduleAnswer = {
      id: moduleEntryId,
      module_id: moduleId,
      user_id: localStorage.getItem("userId"),
      text: moduleInput,
      voice: fReader.result,
      images: modulePhotos,
      username: localStorage.getItem("username"),
    };
    if (moduleAudio) {
      fReader.onloadend = () => {
        moduleAnswer.voice = fReader.result;
        setLoaderEnabled(true);
        api
          .handleSetModuleAnswer(moduleAnswer)
          .then(() => setLoaderEnabled(false));
      };
      fReader.readAsDataURL(moduleAudio);
      return;
    }
    setLoaderEnabled(true);
    api.handleSetModuleAnswer(moduleAnswer).then(() => setLoaderEnabled(false));
  };

  const removeModuleAudio = () => {
    setModuleAudio(undefined);
    const audio = document.getElementById("module-audio") as HTMLAudioElement;
    if (audio) {
      audio.src = "";
      audio.controls = false;
    }
  };

  const getVoiceRecord = (voice: string) => {
    setLoadingVoice(true);
    api
      .getVoice(voice)
      .then((resp) => {
        setVoice(resp);
        const getVoiceRecorded = async () => {
          setModuleAudio(undefined);
          if (resp) {
            setModuleAudio(await (await fetch(resp)).blob());
          }
        };
        getVoiceRecorded();
      })
      .catch(() => {
        console.log("ses kaydı çekilirken hata oluştu");
      })
      .finally(() => {
        setLoadingVoice(false);
      });
  };

  const getImageData = (img: string, index: number) => {
    api
      .getImage(img)
      .then((resp) => {
        uploadedImages[index] = resp;
        setModulePhotos(uploadedImages);
      })
      .catch(() => {
        console.log("image çekilirken hata oluştu");
      });
  };

  useEffect(() => {
    if (!isEmpty(moduleId)) {
      const moduleInfo = async () => {
        try {
          setLoaderEnabled(true);
          const response = await api.getModuleInfo(moduleId);
          setModuleInfo(response);
          const mEntries = await api.getModuleEntryByUserId(
            moduleId,
            localStorage.getItem("userId")
          );
          setModuleEntry(mEntries[0]);
          const moduleData = mEntries[0];
          setLoaderEnabled(false);
          if (moduleData) {
            setModuleInput(moduleData.text);
            setModuleEntryId(moduleData.id);
          } else {
            setModuleInput("");
            setModuleAudio(undefined);
            setModulePhotos(["", "", ""]);
          }
        } catch (error) {}
      };

      moduleInfo();
    }
  }, [moduleId]);

  useEffect(() => {
    if (!isEmpty(moduleEntry)) {
      getVoiceRecord(moduleEntry.voice);
      moduleEntry?.images?.map((img, index) => getImageData(img, index));
    }
  }, [moduleEntry]);

  useEffect(() => {
    if (moduleAudio) {
      const url = URL.createObjectURL(moduleAudio);
      const audio = document.getElementById("module-audio") as HTMLAudioElement;
      audio.src = url;
      audio.controls = true;
    } else removeModuleAudio();
  }, [moduleAudio]);

  return (
    <div className="modal-wrapper">
      <div style={{ paddingTop: "16px" }}>
        <Link className="modal-wrapper__back-arrow" to={`/module/${moduleId}`}>
          <BsArrowLeftSquare></BsArrowLeftSquare>
          &nbsp; &nbsp;
          {`Modül 3`}
        </Link>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="modal-wrapper__modal-letter">
          <div className="modal-wrapper__modal-letter__module-answer-container">
            <textarea
              className="modal-wrapper__modal-letter__module-answer"
              placeholder="Yanıtınız..."
              value={moduleInput}
              onChange={(event) => {
                setModuleInput(event.target.value);
              }}
              style={{ whiteSpace: "pre-line" }}
            ></textarea>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="modal-wrapper__modal-letter__module-audio">
              <VoiceRecorder addAudioTrack={setModuleAudio}></VoiceRecorder>
              {loadingVoice && <div>DENEMECIKKK</div>}
              {!loadingVoice && <audio id="module-audio"></audio>}
              {moduleAudio && (
                <div
                  className="modal-wrapper__modal-letter__module-audio-remove"
                  onClick={removeModuleAudio}
                >
                  <BsTrashFill className="modal-wrapper__modal-letter__module-audio-remove-icon"></BsTrashFill>
                </div>
              )}
            </div>
            <button
              className="modal-wrapper__modal-letter__module-submit"
              onClick={postModuleAnswer}
            >
              Yayınla
            </button>
          </div>
        </div>
      </div>
      <Loader enabled={loaderEnabled}></Loader>
    </div>
  );
};

export default ModuleLetter;
