import { FC } from "react";
import "./index.scss";
import { BsXLg } from "react-icons/bs";
import { User } from "../../helpers/dashboardModels";

export interface UserDeleteModalProps {
  setToBeDeletedUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  deleteUser: () => void;
}

const UserDeleteModal: FC<UserDeleteModalProps> = ({
  setToBeDeletedUser,
  deleteUser,
}) => {
  const handleClose = () => {
    setToBeDeletedUser(undefined);
  };

  const handleDeleteClick = () => {
    deleteUser();
  };

  return (
    <div className="delete-modal-window">
      <div className="delete-modal-container">
        <div className="delete-modal-header">
          <BsXLg className="delete-modal-close" onClick={handleClose}></BsXLg>
        </div>
        <div className="delete-modal-inner-container">
          <span
            style={{
              marginBottom: "32px",
              fontFamily: "Crete Round",
              fontSize: "24px",
            }}
          >
            Bu kullanıcıyı silmek istediğinize emin misiniz?
          </span>
          <div className="delete-modal-footer">
            <button onClick={handleClose} className="delete-modal-delete">
              Hayır
            </button>
            <button onClick={handleDeleteClick} className="delete-modal-delete">
              Evet
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDeleteModal;
