import { FC } from "react";
import loader from "../../images/loader.svg";
import "./index.scss";

export interface LoaderProps {
  enabled: boolean;
}

const Loader: FC<LoaderProps> = ({ enabled = false }) => {
  return (
    <div className="page-loader" style={{ display: enabled ? "flex" : "none" }}>
      <img src={loader} alt="Loader..." className="page-loader__image"></img>
    </div>
  );
};

export default Loader;
