import React, { FC } from "react";
import "./index.scss";
import Modal from "react-modal";
import { ModuleInfo } from "../../helpers/dashboardModels";
import { BsXLg } from "react-icons/bs";

export interface RuleModalProps {
  isOpen: boolean;
  setRuleModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  moduleInfo?: ModuleInfo;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    display: "flex",
    "flex-direction": "column",
    "align-items": "center",
  },
};

const RuleModal: FC<RuleModalProps> = ({
  isOpen = false,
  setRuleModalOpen,
  moduleInfo,
}) => {
  const handleClose = () => {
    setRuleModalOpen(false);
  };
  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose} style={customStyles}>
      <div className="rule-modal__module-header">
        <h2 className="rule-modal__module-title">{`${moduleInfo?.name} Kuralları`}</h2>
        <BsXLg
          onClick={handleClose}
          size="22px"
          style={{ cursor: "pointer" }}
        ></BsXLg>
      </div>
      <div className="rule-modal__seperator"></div>
      <div className="rule-modal__module-text">
        <span>{moduleInfo?.rules}</span>
      </div>
    </Modal>
  );
};

export default RuleModal;
