import React, { FC, useEffect, useState } from "react";
import "./index.scss";
import { ModuleEntryInfo, ModuleInfo } from "../../helpers/dashboardModels";
import api from "../../utils/api";
import { isEmpty, toNumber } from "lodash";
import {
  BsEnvelopeOpen,
  BsExclamationTriangle,
  BsTrashFill,
} from "react-icons/bs";
import RuleModal from "../RuleModal";
import MailModal from "../MailModal";
import ModulePhoto from "../ModulePhoto";
import VoiceRecorder from "../VoiceRecorder";
import Loader from "../Loader";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

const Module: FC = () => {
  const [loaderEnabled, setLoaderEnabled] = useState<boolean>(false);
  const [moduleInfo, setModuleInfo] = useState<ModuleInfo>();
  const [ruleModalOpen, setRuleModalOpen] = useState<boolean>(false);
  const [mailModalOpen, setMailModalOpen] = useState<boolean>(false);
  const [moduleInput, setModuleInput] = useState<string>("");
  const [moduleAudio, setModuleAudio] = useState<Blob>();
  const [modulePhotos, setModulePhotos] = useState<Array<string>>(["", "", ""]);
  const [moduleEntryId, setModuleEntryId] = useState<number | null>(null);
  const [moduleEntry, setModuleEntry] = useState<ModuleEntryInfo>();
  const [voice, setVoice] = useState<string>("");
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const [loadingVoice, setLoadingVoice] = useState<boolean>(false);

  const { moduleId } = useParams() as { moduleId: string };

  const handleModuleRules = () => {
    setRuleModalOpen(true);
  };

  const handleModuleMail = () => {
    setMailModalOpen(true);
  };

  const postModuleAnswer = () => {
    const fReader = new FileReader();

    const moduleAnswer = {
      id: moduleEntryId,
      module_id: moduleId,
      user_id: localStorage.getItem("userId"),
      text: moduleInput,
      voice: fReader.result,
      images: modulePhotos,
      username: localStorage.getItem("username"),
    };
    if (moduleAudio) {
      fReader.onloadend = () => {
        moduleAnswer.voice = fReader.result;
        setLoaderEnabled(true);
        api
          .handleSetModuleAnswer(moduleAnswer)
          .then(() => setLoaderEnabled(false));
      };
      fReader.readAsDataURL(moduleAudio);
      return;
    }
    setLoaderEnabled(true);
    api.handleSetModuleAnswer(moduleAnswer).then(() => setLoaderEnabled(false));
  };

  const removeModuleAudio = () => {
    setModuleAudio(undefined);
    const audio = document.getElementById("module-audio") as HTMLAudioElement;
    if (audio) {
      audio.src = "";
      audio.controls = false;
    }
  };

  const getVoiceRecord = (voice: string) => {
    setLoadingVoice(true);
    api
      .getVoice(voice)
      .then((resp) => {
        setVoice(resp);

        const getVoiceRecorded = async () => {
          setModuleAudio(undefined);
          if (resp) {
            setModuleAudio(await (await fetch(resp)).blob());
          }
        };
        getVoiceRecorded();
      })
      .catch(() => {
        console.log("ses kaydı çekilirken hata oluştu");
      })
      .finally(() => {
        setLoadingVoice(false);
      });
  };

  useEffect(() => {
    if (!isEmpty(moduleId)) {
      const moduleInfo = async () => {
        try {
          setLoaderEnabled(true);
          const response = await api.getModuleInfo(moduleId);
          setModuleInfo(response);
          const mEntries = await api.getModuleEntryByUserId(
            moduleId,
            localStorage.getItem("userId")
          );
          const moduleData = mEntries[0];
          setModuleEntry(moduleData);
          setLoaderEnabled(false);
          if (moduleData) {
            setModuleInput(moduleData.text);
            setModuleEntryId(moduleData.id);
          } else {
            setModuleInput("");
            setModuleAudio(undefined);
            setModulePhotos(["", "", ""]);
          }
        } catch (error) {}
      };

      moduleInfo();
    }
  }, [moduleId]);

  useEffect(() => {
    if (moduleId) {
      setModuleAudio(undefined);
      if (
        !isEmpty(moduleEntry) &&
        moduleEntry.module_id === toNumber(moduleId)
      ) {
        if (moduleEntry.voice) getVoiceRecord(moduleEntry.voice);
        setLoadingImage(true);
        Promise.all(moduleEntry?.images?.map((img) => api.getImage(img)))
          .then((imageArray) => {
            setModulePhotos(imageArray);
          })
          .finally(() => {
            setLoadingImage(false);
          });
      }
    }
  }, [moduleEntry, moduleId]);

  useEffect(() => {
    if (moduleId !== "3" && moduleAudio) {
      const url = URL.createObjectURL(moduleAudio);
      const audio = document.getElementById("module-audio") as HTMLAudioElement;
      audio.src = url;
      audio.controls = true;
    } else removeModuleAudio();
  }, [moduleAudio, moduleId]);

  return (
    <div className="modal">
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 4,
            paddingLeft: "12px",
            paddingRight: "12px",
          }}
        >
          <div className="modal__icons">
            <BsExclamationTriangle
              size={"24px"}
              style={{ cursor: "pointer" }}
              color="#666666"
              onClick={handleModuleRules}
            ></BsExclamationTriangle>
            <BsEnvelopeOpen
              size={"24px"}
              color="#666666"
              style={{ cursor: "pointer" }}
              onClick={handleModuleMail}
            ></BsEnvelopeOpen>
          </div>
          <div className="modal__main">
            <div className="modal__module-container">
              <h2 className="modal__module-title">{moduleInfo?.name}</h2>
              <div className="modal__seperator"></div>
              <div className="modal__module-text">
                <span
                  style={{
                    whiteSpace: "pre-line",
                  }}
                >
                  {moduleInfo?.text}
                </span>
              </div>
            </div>
            <div className="modal__module-form">
              {moduleId !== "3" && (
                <div className="modal__module-answer-container">
                  <textarea
                    className="modal__module-answer"
                    placeholder="Yanıtınız..."
                    value={moduleInput}
                    onChange={(event) => {
                      setModuleInput(event.target.value);
                    }}
                  ></textarea>
                </div>
              )}
              {moduleId !== "3" && (
                <div className="modal__module-audio">
                  <VoiceRecorder addAudioTrack={setModuleAudio}></VoiceRecorder>
                  {loadingVoice && <div>Yükleniyor...</div>}
                  {!loadingVoice && moduleAudio && (
                    <audio id="module-audio"></audio>
                  )}
                  {moduleAudio && (
                    <div
                      className="modal__module-audio-remove"
                      onClick={removeModuleAudio}
                    >
                      <BsTrashFill className="modal__module-audio-remove-icon"></BsTrashFill>
                    </div>
                  )}
                </div>
              )}
              {moduleId !== "3" ? (
                <button
                  className="modal__module-submit"
                  onClick={postModuleAnswer}
                >
                  Yayınla
                </button>
              ) : (
                <div className="modal__module-letter">
                  <Link to={`/module/${moduleId}/letter`}>
                    <button
                      className="modal__module-letter-inner"
                      onClick={() => {}}
                    >
                      Mektubunuz
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        {loadingImage && (
          <div
            className="modal__module-photos"
            style={{ height: "100%", display: "flex", alignItems: "center" }}
          >
            Yükleniyor...
          </div>
        )}
        {!loadingImage && moduleInfo?.img && (
          <div className="modal__module-photos">
            <ModulePhoto
              idx={0}
              modulePhotos={modulePhotos}
              setModulePhotos={setModulePhotos}
            ></ModulePhoto>
            <ModulePhoto
              idx={1}
              modulePhotos={modulePhotos}
              setModulePhotos={setModulePhotos}
            ></ModulePhoto>
            <ModulePhoto
              idx={2}
              modulePhotos={modulePhotos}
              setModulePhotos={setModulePhotos}
            ></ModulePhoto>
          </div>
        )}
        <RuleModal
          isOpen={ruleModalOpen}
          setRuleModalOpen={setRuleModalOpen}
          moduleInfo={moduleInfo}
        />
        <MailModal isOpen={mailModalOpen} setMailModalOpen={setMailModalOpen} />
        {/* <MailModal
          isOpen={deleteModalOpen}
          setDeleteModalOpen={setDeleteModalOpen}
        /> */}
      </>

      <Loader enabled={loaderEnabled}></Loader>
    </div>
  );
};

export default Module;
