import { FC } from "react";
import "./index.scss";
import { BsXLg } from "react-icons/bs";
import { User } from "../../helpers/dashboardModels";

export interface UserPreviewModalProps {
  userInfo: User;
  setCreatedUser: React.Dispatch<React.SetStateAction<User | undefined>>;
}

const UserPreviewModal: FC<UserPreviewModalProps> = ({
  userInfo,
  setCreatedUser,
}) => {
  const handleClose = () => {
    setCreatedUser(undefined);
  };
  return (
    <div className="user-preview-window">
      <div className="user-preview-container">
        <div className="user-preview-header">
          <div className="user-preview-header-title">
            Kullanıcı oluşturuldu: {userInfo.full_name}
          </div>
          <BsXLg className="user-preview-close" onClick={handleClose}></BsXLg>
        </div>
        <div className="user-preview-body">
          <div className="user-preview-body-item">
            <div className="user-preview-body-item-title">
              Lütfen parolayı not alınız, bir daha görünmeyecektir.
            </div>
          </div>
          <div className="user-preview-body-item">
            <div className="user-preview-body-item-title">Parola</div>
            <div className="user-preview-body-item-title">
              {userInfo.password}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPreviewModal;
