import { FC, useState } from "react";
import "./index.scss";
import { BsXLg } from "react-icons/bs";
import { User } from "../../helpers/dashboardModels";

export interface UserEditModalProps {
  userInfo: User;
  setSelectedUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  updateSelectedUser: (user: User) => void;
  createNewUser: (user: User) => void;
}

const UserEditModal: FC<UserEditModalProps> = ({
  userInfo,
  setSelectedUser,
  updateSelectedUser,
  createNewUser,
}) => {
  const [userModel, setUserModel] = useState<User>(userInfo);
  const handleClose = () => {
    setSelectedUser(undefined);
  };
  const handleUserSubmit = () => {
    if (userInfo.id < 0) {
      createNewUser(userModel);
    } else updateSelectedUser(userModel);
  };
  return (
    <div className="user-edit-window">
      <div className="user-edit-container">
        <div className="user-edit-header">
          <div className="user-edit-header-title">
            Düzenlenen kullanıcı: {userModel.full_name}
          </div>
          <BsXLg className="user-edit-close" onClick={handleClose}></BsXLg>
        </div>
        <div className="user-edit-body">
          <div className="user-edit-body-item">
            <div className="user-edit-body-item-title">Kullanıcı adı</div>
            <input
              defaultValue={userModel.email}
              onChange={(e) =>
                setUserModel({
                  ...userModel,
                  email: e.currentTarget.value,
                })
              }
            ></input>
          </div>
          <div className="user-edit-body-item">
            <div className="user-edit-body-item-title">İsim</div>
            <input
              defaultValue={userModel.full_name}
              onChange={(e) =>
                setUserModel({
                  ...userModel,
                  full_name: e.currentTarget.value,
                })
              }
            ></input>
          </div>
          <div className="user-edit-body-item">
            <div className="user-edit-body-item-title">Yetki</div>
            <select
              defaultValue={userModel.role}
              onChange={(e) =>
                setUserModel({
                  ...userModel,
                  role: parseInt(e.currentTarget.value),
                })
              }
            >
              <option value={0}>Devre dışı kullanıcı</option>
              <option value={1}>Aktif kullanıcı</option>
              <option value={2}>Yönetici</option>
            </select>
          </div>
        </div>
        <div className="user-edit-submit">
          <button
            className="user-edit-submit-button"
            onClick={handleUserSubmit}
          >
            Kaydet
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserEditModal;
