import React, { FC, useEffect, useState } from "react";
import "./index.scss";
import Modal from "react-modal";
import { BsXLg } from "react-icons/bs";
import { useParams } from "react-router-dom";
import api from "../../utils/api";

export interface MailModalProps {
  isOpen: boolean;
  setMailModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    display: "flex",
    "flex-direction": "column",
    "align-items": "center",
  },
};

const MailModal: FC<MailModalProps> = ({
  isOpen = false,
  setMailModalOpen: setRuleModalOpen,
}) => {
  const [mailInput, setMailInput] = useState<string>("");
  const { moduleId } = useParams() as { moduleId: string };
  const userId = localStorage.getItem("userId");
  const handleClose = () => {
    setRuleModalOpen(false);
  };

  const handleSentMail = () => {
    const mailRequest = {
      text: mailInput,
      module_id: parseInt(moduleId),
      user_id: userId,
    };
    api.handleSendMail(mailRequest).then(() => {
      setMailInput("");
      handleClose();
    });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose} style={customStyles}>
      <div className="mail-modal__module-header">
        <h2 className="mail-modal__module-title">Araştırmacıya Ulaşın</h2>
        <BsXLg
          onClick={handleClose}
          size="22px"
          style={{ cursor: "pointer" }}
        ></BsXLg>
      </div>
      <div className="mail-modal__seperator"></div>
      <div className="mail-modal__module-body">
        <div className="mail-modal__module-form">
          <textarea
            className="mail-modal__module-form-textarea"
            value={mailInput}
            onChange={(event) => {
              setMailInput(event.target.value);
            }}
          ></textarea>
          <button
            onClick={handleSentMail}
            className="mail-modal__module-form-submit"
          >
            Gönder
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MailModal;
