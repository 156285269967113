import { FC, FormEvent, useRef } from "react";
import { BsTrash, BsUpload } from "react-icons/bs";
import "./index.scss";
import imageCompression from "browser-image-compression";

export interface ModulePhotoProps {
  idx: number;
  modulePhotos: string[];
  onClick?: () => void;
  setModulePhotos: React.Dispatch<React.SetStateAction<string[]>>;
}

const ModulePhoto: FC<ModulePhotoProps> = ({
  idx,
  onClick,
  modulePhotos,
  setModulePhotos,
}) => {
  const uploadHandle = useRef<HTMLInputElement>(null);

  const fileClickHandler = () => {
    uploadHandle.current?.click();
  };
  const removePhoto = () => {
    let updated = [...modulePhotos];
    updated[idx] = "";
    setModulePhotos(updated);
  };
  const fileSelectHandler = (e: FormEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    if (files && files.length > 0 && files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const data = reader.result?.toString() || "";
        let updated = [...modulePhotos];
        updated[idx] = data;
        setModulePhotos(updated);
      };
      console.log("original: ", files[0]);
      imageCompression(files[0], options)
        .then((compressedImage) => {
          console.log("compressed: ", compressedImage.size);
          reader.readAsDataURL(compressedImage);
        })
        .catch(function (error) {
          alert("Fotoğraf yüklenemedi. Lütfen tekrar deneyin.");
        });
    } else {
      removePhoto();
    }
  };

  return (
    <div
      className="module-photo-placeholder"
      style={{
        backgroundColor: "lightgray",
        backgroundImage: modulePhotos[idx] && `url("${modulePhotos[idx]}")`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
        color: modulePhotos[idx] && "white",
      }}
    >
      <input
        ref={uploadHandle}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={fileSelectHandler}
      ></input>
      <BsUpload
        size={"24px"}
        color={modulePhotos[idx] && "white"}
        style={{ cursor: "pointer" }}
        onClick={fileClickHandler}
      ></BsUpload>
      <span onClick={fileClickHandler}>Fotoğraf Yükle</span>
      {modulePhotos[idx] && (
        <BsTrash className="remove-icon" onClick={removePhoto}></BsTrash>
      )}
    </div>
  );
};

export default ModulePhoto;
