import React, { FC, useEffect, useState } from "react";
import "./index.scss";
import { ModuleEntryInfo } from "../../helpers/dashboardModels";
import api from "../../utils/api";
import { BsEnvelopeOpen } from "react-icons/bs";
import MailModal from "../MailModal";
import ModuleEntry from "../ModuleEntry";
import Loader from "../Loader";
import { useParams } from "react-router-dom";

export interface ModuleEntriesProps {}

const ModuleEntries: FC<ModuleEntriesProps> = () => {
  const [loaderEnabled, setLoaderEnabled] = useState<boolean>(false);
  const [moduleEntries, setModuleEntries] = useState<Array<ModuleEntryInfo>>();
  const [mailModalOpen, setMailModalOpen] = useState<boolean>(false);

  const { moduleId } = useParams() as { moduleId: string };

  const handleModuleMail = () => {
    setMailModalOpen(true);
  };

  useEffect(() => {
    if (moduleId !== "0") {
      setLoaderEnabled(true);
      const moduleInfo = async () => {
        try {
          const response = await api.getModuleEntries(moduleId);
          setModuleEntries(response);
          setLoaderEnabled(false);
        } catch (error) {}
      };

      moduleInfo();
    }
  }, [moduleId]);

  return (
    <div className="module-entries">
      <div
        style={{
          width: "90%",
          display: "flex",
          flexDirection: "column",
          paddingBottom: "30px",
        }}
      >
        <div className="module-entries__icons">
          <div></div>
          <span className="module-entries__forum-title">Forum {moduleId}</span>
          <BsEnvelopeOpen
            size={"24px"}
            color="#666666"
            style={{ cursor: "pointer" }}
            onClick={handleModuleMail}
          ></BsEnvelopeOpen>
        </div>
        {moduleEntries && (
          <div className="module-entries__module-container">
            {moduleEntries.map((element) => {
              return (
                <ModuleEntry
                  moduleEntryData={element}
                  moduleId={moduleId}
                ></ModuleEntry>
              );
            })}
            {/* <ForumEntry></ForumEntry> */}
          </div>
        )}
      </div>
      <MailModal isOpen={mailModalOpen} setMailModalOpen={setMailModalOpen} />
      <Loader enabled={loaderEnabled}></Loader>
    </div>
  );
};

export default ModuleEntries;
