import { FC } from "react";
import { AudioRecorder } from "react-audio-voice-recorder";
import "./index.scss";

export interface VoiceRecorderProps {
  addAudioTrack?: React.Dispatch<React.SetStateAction<Blob | undefined>>;
}

const VoiceRecorder: FC<VoiceRecorderProps> = ({ addAudioTrack }) => {
  return (
    <div className="voice-recoder-pane">
      <AudioRecorder
        onRecordingComplete={addAudioTrack}
        audioTrackConstraints={{
          noiseSuppression: true,
          echoCancellation: true,
        }}
        onNotAllowedOrFound={(err) => console.table(err)}
        downloadOnSavePress={false}
        downloadFileExtension="webm"
        mediaRecorderOptions={{
          audioBitsPerSecond: 128000,
        }}
        // showVisualizer={true}
      />
      <br />
    </div>
  );
};

export default VoiceRecorder;
