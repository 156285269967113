import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage/index";
import Dashboard from "./pages/Dashboard/index";
import Module from "./components/Module";
import ModuleEntries from "./components/ModuleEntries";
import Admin from "./components/Admin";
import Rules from "./components/Rules";
import Welcome from "./components/Welcome";
import ModuleLetter from "./components/ModuleLetter";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<Dashboard />}>
          <Route index element={<Welcome />} />
          <Route path="rules/" element={<Rules />} />
          <Route path="module/:moduleId" element={<Module />} />
          <Route path="module/:moduleId/letter" element={<ModuleLetter />} />
          <Route path="forum/:moduleId" element={<ModuleEntries />} />
          <Route path="admin/" element={<Admin />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
