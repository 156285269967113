import React, { FC } from "react";
import "./index.scss";
import ModuleItem from "../ModuleItem";
import { ModuleItemType } from "../../helpers/dashboardModels";

export interface ModulesProps {
  setSelectedItemId: React.Dispatch<React.SetStateAction<string>>;
  modules: ModuleItemType[] | undefined;
}

const Modules: FC<ModulesProps> = ({ setSelectedItemId, modules }) => {
  return (
    <div className="modules">
      <h3 className="modules__title">Modüller</h3>
      <div className="modules__list">
        {modules &&
          modules
            .sort((a, b) => a.id - b.id)
            .map((module) => <ModuleItem key={module.id} id={module.id} />)}
      </div>
    </div>
  );
};

export default Modules;
