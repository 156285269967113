import { FC } from "react";
import "./index.scss";
import { BsXLg } from "react-icons/bs";

export interface DeleteModalProps {
  setDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteModal: () => void;
}

const DeleteModal: FC<DeleteModalProps> = ({
  setDeleteModalOpen,
  handleDeleteModal,
}) => {
  const handleClose = () => {
    setDeleteModalOpen(false);
  };

  const handleDeleteClick = () => {
    setDeleteModalOpen(false);
    handleDeleteModal();
  };

  return (
    <div className="delete-modal-window">
      <div className="delete-modal-container">
        <div className="delete-modal-header">
          <BsXLg className="delete-modal-close" onClick={handleClose}></BsXLg>
        </div>
        <div className="delete-modal-inner-container">
          <span
            style={{
              marginBottom: "32px",
              fontFamily: "Crete Round",
              fontSize: "24px",
            }}
          >
            Bu yorumu silmek istediğinize emin misiniz?
          </span>
          <div className="delete-modal-footer">
            <button onClick={handleClose} className="delete-modal-delete">
              Hayır
            </button>
            <button onClick={handleDeleteClick} className="delete-modal-delete">
              Evet
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
