import React, { FC } from "react";
import "./index.scss";
import { BsChatText, BsPencilSquare } from "react-icons/bs";
import { Link } from "react-router-dom";

export interface ModuleItemProps {
  id: number;
}

const ModuleItem: FC<ModuleItemProps> = ({ id }) => {
  return (
    <div className="module-item">
      <Link className="module-item-module" to={`module/${id}`}>
        <div className="module-item-module-content">
          {`Modül ${id}`}
          &nbsp; &nbsp;
          <BsPencilSquare></BsPencilSquare>
        </div>

        <Link className="module-item-forum" to={`forum/${id}`}>
          <BsChatText></BsChatText>
          <span className="module-item-forum-tag">&nbsp; &nbsp;Forum</span>
        </Link>
      </Link>
    </div>
  );
};

export default ModuleItem;
