import { FC, useState } from "react";
import "./index.scss";
import {
  BsEnvelopePaperFill,
  BsFillChatLeftDotsFill,
  BsTrash3Fill,
} from "react-icons/bs";
import { CommentEntry } from "../../helpers/dashboardModels";
import { isEmpty } from "lodash";
import api from "../../utils/api";
import DeleteModal from "../CommentDeleteModal";

export interface ForumEntryProps {
  commentEntry?: CommentEntry;
  moduleEntryId: string;
  parentId?: string;
  moduleId?: string;
  level: number;
}

const ForumEntry: FC<ForumEntryProps> = ({
  commentEntry,
  moduleEntryId,
  parentId,
  moduleId,
  level,
}) => {
  const [enteredCommentEntry, setEnteredCommentEntry] = useState<string>("");
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [forumEntry, setForumEntry] = useState<object>({});
  const [forumEntryCommentClicked, setForumEntryCommentClicked] =
    useState<boolean>(false);
  const role = localStorage.getItem("userRole") || "0";
  const submitForumComment = () => {
    const forumAnswer = {
      module_id: commentEntry?.module_id || moduleId,
      module_entry_id: moduleEntryId,
      user_id: localStorage.getItem("userId"),
      text: enteredCommentEntry,
      parent_id: commentEntry?.id || parentId,
      username: localStorage.getItem("username"),
    };
    api.handleSetCommentEntry(forumAnswer).then(() => {
      window.location.reload();
    });
  };

  const handleForumComment = () => {
    setForumEntryCommentClicked(true);
  };

  const handleDeleteComment = () => {
    const forumAnswer = {
      module_id: commentEntry?.module_id.toString() || moduleId,
      module_entry_id: moduleEntryId,
      user_id: localStorage.getItem("userId"),
      text: commentEntry?.text,
      parent_id: commentEntry?.id.toString() || parentId,
      id: commentEntry?.id.toString(),
      username: localStorage.getItem("username"),
    };
    setForumEntry(forumAnswer);
    setDeleteModalOpen(true);
  };

  const handleDeleteModal = () => {
    api.handleDeleteCommentEntry(forumEntry).then(() => {
      window.location.reload();
    });
  };

  return (
    <div className="forum-entry">
      {deleteModalOpen && (
        <DeleteModal
          handleDeleteModal={handleDeleteModal}
          setDeleteModalOpen={setDeleteModalOpen}
        ></DeleteModal>
      )}
      <div className="forum-entry-container">
        <div className="forum-entry-title">
          {commentEntry
            ? commentEntry.username
            : localStorage.getItem("username")}
          {parseInt(role) === 2 && (
            <BsTrash3Fill
              className="forum-entry-trash"
              onClick={handleDeleteComment}
            ></BsTrash3Fill>
          )}
        </div>
        <div className="forum-entry-body">
          {commentEntry ? (
            commentEntry.text
          ) : (
            <textarea
              className="modal__module-answer"
              placeholder="Yanıtınız..."
              value={enteredCommentEntry}
              onChange={(event) => {
                setEnteredCommentEntry(event.target.value);
              }}
            ></textarea>
          )}
        </div>
        {isEmpty(commentEntry) ? (
          <div className="forum-entry-comment">
            <button
              onClick={submitForumComment}
              className="forum-entry__forum-submit"
            >
              <BsEnvelopePaperFill />
              &nbsp; Yayınla
            </button>
          </div>
        ) : (
          <div
            className="module-entry-footer-comment"
            style={{ cursor: "pointer" }}
            onClick={handleForumComment}
          >
            <BsFillChatLeftDotsFill />
            &nbsp; Yorum Yap
          </div>
        )}
      </div>

      {commentEntry && commentEntry.replies && (
        <div className="forum-entry-child-container">
          {level < 4 && <div className="forum-entry-child-spacer"></div>}
          <div className="forum-entry-child">
            {commentEntry.replies.map((element) => {
              return (
                <ForumEntry
                  commentEntry={element}
                  moduleEntryId={moduleEntryId}
                  level={level + 1}
                ></ForumEntry>
              );
            })}
            {forumEntryCommentClicked && (
              <ForumEntry
                moduleEntryId={moduleEntryId}
                parentId={commentEntry.id.toString()}
                moduleId={commentEntry.module_id.toString()}
                level={level + 1}
              ></ForumEntry>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ForumEntry;
