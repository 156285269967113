import { FC, useEffect, useState } from "react";
import "./index.scss";
import { ModuleInfo, User } from "../../helpers/dashboardModels";
import api from "../../utils/api";
import { BsPencilFill, BsPlusCircle, BsTrash2Fill } from "react-icons/bs";
import UserEditModal from "../UserEditModal";
import ModuleEditModal from "../ModuleEditModal";
import UserPreviewModal from "../UserPreviewModal";
import UserDeleteModal from "../UserDeleteModal";

export interface AdminProps {}

const Admin: FC<AdminProps> = () => {
  const [modules, setModules] = useState<Array<ModuleInfo>>();
  const [users, setUsers] = useState<Array<User>>();
  const [selectedUser, setSelectedUser] = useState<User>();
  const [createdUser, setCreatedUser] = useState<User>();
  const [selectedModule, setSelectedModule] = useState<ModuleInfo>();
  const [toBeDeletedUser, setToBeDeletedUser] = useState<User>();
  useEffect(() => {
    updateUserList();
    updateModuleList();
  }, []);

  const updateUserList = () => {
    api.getUserList().then((userData: Array<User>) => {
      setUsers(userData);
    });
  };

  const updateModuleList = () => {
    api.getModuleList().then((moduleData: Array<ModuleInfo>) => {
      setModules(moduleData);
    });
  };

  const deleteUser = () => {
    api.deleteUser(toBeDeletedUser).then(()=> {
      alert("Kullanıcı silinmiştir.");
      window.location.reload();
    }).catch(()=> alert("Kullanıcı silerken bir hata meydana geldi."));
  };

  const updateSelectedUser = (user: User) => {
    api
      .updateUserInfo(user)
      .then(() => {
        alert("Kullanıcı güncellendi");
        setSelectedUser(undefined);
        updateUserList();
      })
      .catch(() => {
        alert("Kullanıcı güncellenirken bir hata meydana geldi");
      });
  };
  const createNewUser = (user: User) => {
    api
      .createNewUser({ full_name: user.full_name, email: user.email })
      .then((createdUser) => {
        setCreatedUser(createdUser);
        updateUserList();
      })
      .catch(() => {
        alert("Kullanıcı oluşturulurken bir hata meydana geldi");
      });
  };
  const handleCreateUser = () => {
    const newUser: User = {
      id: -1,
      email: "",
      full_name: "Yeni Kullanıcı",
      role: 0,
    };
    setSelectedUser(newUser);
  };
  const updateSelectedModule = (module: ModuleInfo) => {
    api
      .updateModuleInfo(module)
      .then(() => {
        alert("Modül güncellendi");
        setSelectedModule(undefined);
        updateModuleList();
      })
      .catch(() => {
        alert("Modül güncellenirken bir hata meydana geldi");
      });
  };

  return (
    <div className="admin-panel">
      {!!toBeDeletedUser && <UserDeleteModal setToBeDeletedUser={setToBeDeletedUser} deleteUser={deleteUser}></UserDeleteModal>}
      {selectedUser && (
        <UserEditModal
          userInfo={selectedUser}
          setSelectedUser={setSelectedUser}
          updateSelectedUser={updateSelectedUser}
          createNewUser={createNewUser}
        ></UserEditModal>
      )}
      {selectedModule && (
        <ModuleEditModal
          moduleInfo={selectedModule}
          setSelectedModule={setSelectedModule}
          updateSelectedModule={updateSelectedModule}
        ></ModuleEditModal>
      )}
      {createdUser && (
        <UserPreviewModal
          userInfo={createdUser}
          setCreatedUser={setCreatedUser}
        ></UserPreviewModal>
      )}
      <div className="admin-panel-header">Yönetim Paneli</div>
      <div className="admin-panel-container">
        <div className="admin-panel-list">
          <div className="admin-panel-list-header">
            Kullanıcılar
            <div
              className="admin-panel-list-header-action"
              onClick={handleCreateUser}
            >
              <BsPlusCircle></BsPlusCircle> &nbsp; Kullanıcı ekle
            </div>
          </div>
          <div className="admin-panel-list-container">
            {users?.map((user) => {
              return (
                <div className="admin-panel-list-item">
                  {user.full_name}
                  <div className="admin-panel-list-actions">
                    <div
                      className="admin-panel-list-actions"
                      title="Düzenle"
                      onClick={() => setSelectedUser(user)}
                    >
                      <BsPencilFill className="admin-panel-list-actions-item"></BsPencilFill>
                    </div>
                    <div
                      className="admin-panel-list-actions"
                      title="Sil"
                      onClick={() => setToBeDeletedUser(user)}
                    >
                      <BsTrash2Fill className="admin-panel-list-actions-item"></BsTrash2Fill>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="admin-panel-list">
          <div className="admin-panel-list-header">Modüller</div>
          <div className="admin-panel-list-container">
            {modules
              ?.sort((a, b) => a.id - b.id)
              .map((module) => {
                return (
                  <div className="admin-panel-list-item">
                    {module.name}
                    <div className="admin-panel-list-actions">
                      <div
                        className="admin-panel-list-actions"
                        title="Düzenle"
                        onClick={() => setSelectedModule(module)}
                      >
                        <BsPencilFill className="admin-panel-list-actions-item"></BsPencilFill>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
